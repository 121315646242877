import axios from 'axios';
import { app_version } from './Utils';

export const serverUrl = `${process.env.REACT_APP_SERVER_URL || ''}`;

axios.defaults.headers.post['Content-Type'] ='application/json';

const ArthaGateway = {
    validateLogin: async function(dealerCode, password) {
        try {
            const payload = { id: dealerCode, password: password };
            let response = await axios.post(serverUrl + '/master/dealer/validate', payload);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::validateLogin ->"+ error);
            throw error;
        }
        return false;
    },
    getDealers: async function(salesPersonId, reportsToId, name = "", districtId = 0, talukId = 0, pageNumber = 1, pageSize = 10, dealerType = 0, activeOnly = true) {
        try {
            const params = { salesPersonId, reportsToId, name, talukId, districtId, pageNumber, pageSize, dealerType, activeOnly  };
            let response = await axios.get(serverUrl + '/master/dealer', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getDealers ->"+ error);
            throw error;
        }
        return undefined;
    },
    getBalance: async function(accountId) {
        try {
            let response = await axios.get(serverUrl + '/accounting/account/'+ accountId.toString() + '/balance');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getDealer ->"+ error);
            throw error;
        }
        return undefined;
    },
    getTransactions: async function(accountId, durationId, pageNumber = 1, pageSize = 10) {
        try {
            if(pageNumber <= 0) { 
                pageNumber = 1; 
            }
            const params = { durationId, accountId, pageNumber, pageSize  };
            let response = await axios.get(serverUrl + '/accounting/account/'+ accountId + "/transaction", { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getBusinessSummary: async function(partId, durationId) {
        try {
            const params = { durationId  };
            let response = await axios.get(serverUrl + '/master/party/businessSummary/'+ partId, { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSalesPersonBusinessSummary: async function(salesPersonId, durationId) {
        try {
            const params = { salesPersonId, durationId  };
            let response = await axios.get(serverUrl + '/master/salesPerson/businessSummary/', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getSalesPersonBusinessSummary ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSalesPersonOutstandingSummary: async function(salesPersonId, asOn) {
        try {
            const params = { salesPersonId, asOn  };
            let response = await axios.get(serverUrl + '/master/salesPerson/outstandingSummary/', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getSalesPersonOutstandingSummary ->"+ error);
            throw error;
        }
        return undefined;
    },
    getPendingInvoices: async function(partyId) {
        try {
            const params = { partyId  };
            let response = await axios.get(serverUrl + '/voucher/sale/pending', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getPendingInvoices ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSalesperson: async function() {
        try {
            const params = { activeOnly: true, reportsTo: -1  };
            let response = await axios.get(serverUrl + '/master/salesPerson', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSales: async function(partyId: number, durationId: string, salesPersonId: number, pageNumber: number = 1, pageSize: number = 10) {
        try {
            const params = { partyId, durationId, salesPersonId, aymentType: 0, departmentId: 0, companyId: 0, pageNumber, pageSize  };
            let response = await axios.get(serverUrl + '/voucher/sale', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSalesSummary: async function(partyId: number, durationId: string, salesPersonId: number) {
        try {
            const params = { partyId, durationId, salesPersonId, aymentType: 0, departmentId: 0, companyId: 0  };
            let response = await axios.get(serverUrl + '/voucher/sale/summary', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getReceipts: async function(partyId: number, durationId: string, salesPersonId: number, pageNumber: number = 1, pageSize: number = 10) {
        try {
            const params = { partyId, durationId, salesPersonId, aymentType: 0, departmentId: 0, companyId: 0, pageNumber, pageSize  };
            let response = await axios.get(serverUrl + '/voucher/receipt', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getReceiptsSummary: async function(partyId: number, durationId: string, salesPersonId: number) {
        try {
            const params = { partyId, durationId, salesPersonId, aymentType: 0, departmentId: 0, companyId: 0  };
            let response = await axios.get(serverUrl + '/voucher/receipt/summary', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getCreditNotes: async function(partyId: number, durationId: string, salesPersonId: number, pageNumber: number = 1, pageSize: number = 10) {
        try {
            const params = { partyId, durationId, salesPersonId, pageNumber, pageSize  };
            let response = await axios.get(serverUrl + '/voucher/creditNote', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getCreditNote ->"+ error);
            throw error;
        }
        return undefined;
    },
    getCreditNotesSummary: async function(partyId: number, durationId: string, salesPersonId: number) {
        try {
            const params = { partyId, durationId, salesPersonId, aymentType: 0, departmentId: 0, companyId: 0  };
            let response = await axios.get(serverUrl + '/voucher/creditNote/summary', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getCreditNoteSummary ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSalesFileLink: async function(saleId) {
        try {
            let response = await axios.get(serverUrl + '/voucher/sale/pdf/'+ saleId);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getSalesOrderFileLink: async function(salesOrderId) {
        try {
            let response = await axios.get(serverUrl + '/voucher/order/pdf/'+ salesOrderId);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getReceiptFileLink: async function(receiptId) {
        try {
            let response = await axios.get(serverUrl + '/voucher/receipt/pdf/'+ receiptId);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getReceiptFileLink ->"+ error);
            throw error;
        }
        return undefined;
    },
    getCreditNoteFileLink: async function(creditNoteId) {
        try {
            let response = await axios.get(serverUrl + '/voucher/creditNote/pdf/'+ creditNoteId);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getCreditNoteFileLink ->"+ error);
            throw error;
        }
        return undefined;
    },
    getDealerStatementFileLink: async function(accountId, durationId) {
        try {
            const params = { durationId  };
            let response = await axios.get(serverUrl + '/master/dealer/statement/pdf/'+ accountId, { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getOutstandingReportFileLink: async function(dealerId) {
        try {
            let response = await axios.get(serverUrl + '/master/dealer/outstanding/pdf/'+ dealerId);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getOrders: async function(partyId: number, durationId: string, salesPersonId: number, deliveryStatus: number, pageNumber: number = 1, pageSize: number = 10) {
        try {
            const params = { partyId, durationId, salesPersonId, deliveryStatus, departmentId: 0, companyId: 0, pageNumber, pageSize  };
            let response = await axios.get(serverUrl + '/voucher/order', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getOrdersSummary: async function(partyId: number, durationId: string, salesPersonId: number, deliveryStatus: number) {
        try {
            const params = { partyId, durationId, salesPersonId, deliveryStatus, departmentId: 0, companyId: 0  };
            let response = await axios.get(serverUrl + '/voucher/order/summary', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getCreditNoteSummary ->"+ error);
            throw error;
        }
        return undefined;
    },
    getCreditNoteEligibality: async function(partyId: number) {
        try {
            const params = { partyId };
            let response = await axios.get(serverUrl + '/reports/party/creditNoteEligibality', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },    
    initiatePayment: async function(partyId, amount) {
        try {
            let redirectionUrl = window.location.origin + "/payment/result";
            const params = { partyId, amount, redirectionUrl  };
            let response = await axios.get(serverUrl + '/phonepe/initiate', { params });
            if (response) {  
                console.log(response);
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },    
    processPayment: async function(tid, churn) {
        try {
            const params = { tid, churn  };
            let response = await axios.get(serverUrl + '/phonepe/process', { params });
            if (response) {  
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    logActivity: async function(activity) {
        try {
            let logActivity = { ...activity, appVersion: app_version }
            let response = await axios.post(serverUrl + '/master/dealer/app/activity', logActivity);
            if (response) {  
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::logActivity ->"+ error);
            throw error;
        }
        return undefined;
    }
}

export default ArthaGateway;