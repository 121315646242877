
import React, { useState, useEffect } from 'react';

export const Footer = () => {
    return (
        <div>
            <div className="navbar sticky-bottom navbar-dark bg-black footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="widget">
                                <ul>
                                    <li><a title="Company Profile" href="/about">Company Profile</a></li>
                                    <li><a title="Privacy Policy" href="/privacy">Privacy Policy</a></li>
                                    <li><a title="Cancellation\Return Policy" href="/cancellation">Cancellation\Return Policy</a></li>
                                    <li><a title="Delivery\Shipping Policy" href="/shipping">Delivery\Shipping Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="widget">
                                <h5 className="widget-title font-alt">Address</h5>
                                <div>
                                    Malladihalli - 577531, Holalkere(T), Chitradurga, Karnataka, India<br />
                                    Phone: <a href="tel:+917338178090">+91 7338178090</a> / <a href="tel:+917892832318">+91 7892832318</a><br />
                                    Email: <a href="mailto:amruth.organic@gmail.com">amruth.organic@gmail.com</a><br />
                                    <a href="mailto:info@amruthgroup.net">info@amruthgroup.net</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}