import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dealer } from 'service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCreditCard, faPhone } from '@fortawesome/free-solid-svg-icons';
import ArthaGateway from 'service/ArthaGateway';
import { app_version, clearCache } from 'service/Utils';

export const Dashboard = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<Dealer>();
    const [balance, setBalance] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBalanceLoading, setIsBalanceLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setDealer(user.profile.profile);
            setIsLoading(false);
        }
    }, [user]);

    useEffect(() => {
        if (!dealer) {
            return;
        }
        ArthaGateway
            .getBalance(dealer.accountId)
            .then((data) => {
                let totalBalance = data.reduce((sum, d) => sum + d.balance, 0);
                setBalance(totalBalance);
                setIsBalanceLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
                setIsBalanceLoading(false)
            });

        ArthaGateway
            .logActivity({
                partyId: dealer.partyId,
                activity: "View dashboard",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }, [dealer]);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <>
            <div className="page-dashboard">
                <div className='page-header bg-dark' style={{ paddingBottom: "20px" }}>
                    Welcome,
                    <h2>{dealer?.name}</h2>
                    <p>{dealer?.gstin}</p>
                </div>
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        <div className='balance card'>
                            <div className='card-background'></div>
                            <div className="card-body">
                                <h5 className="card-title">Your balance is</h5>
                                <p className="card-text">
                                    <div className={(balance != undefined && balance > 0) ? "badge bg-danger" : "badge bg-success"}>
                                        {isBalanceLoading &&
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        {!isBalanceLoading &&
                                            <h3>{currencyFormat(balance)}</h3>
                                        }
                                    </div>
                                    <NavLink to="/payment" className="btn btn-primary cta">
                                        <FontAwesomeIcon icon={faCreditCard} style={{ width: "auto" }} />
                                        &nbsp; Make Payment
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                        <div className='banking card'>
                            <div className='card-background'></div>
                            <div className="card-body">
                                <h5 className="card-title">Bank Details</h5>
                                <p className="card-text">
                                    Account : <b>Amruth Organic Fertilizers</b><br />
                                    Bank Name: <b>Axis Bank</b><br />
                                    Branch: <b>Channagiri</b><br />
                                    IFSC Code: <b>UTIB0004316</b>
                                    <div className="btn" onClick={() => { navigator.clipboard.writeText("UTIB0004316") }} style={{ padding: "0 0 0 10px" }}>
                                        <FontAwesomeIcon icon={faCopy} style={{ width: "auto" }} />
                                    </div>
                                    <br />
                                    Account No: <b>922030051691822</b>
                                    <div className="btn" onClick={() => { navigator.clipboard.writeText("922030051691822") }} style={{ padding: "0 0 0 10px" }}>
                                        <FontAwesomeIcon icon={faCopy} style={{ width: "auto" }} />
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div className='contact card'>
                            <div className='card-background'></div>
                            <div className="card-body">
                                <h5 className="card-title">Marketting Person</h5>
                                <p className="card-text">
                                    {user.profile.profile.salesPerson.name}<br />
                                    {user.profile.profile.salesPerson.mobileNumber}
                                </p>
                                <a className='btn btn-primary cta' href={"tel:" + user.profile.profile.salesPerson.mobileNumber} style={{ textDecoration: "none", color: "#fff" }}>
                                    <FontAwesomeIcon icon={faPhone} style={{ width: "auto" }} />
                                    &nbsp; Call
                                </a>
                            </div>
                        </div>
                        <div style={{ position: "relative", height: 50 }}>
                            <div className='version-number'>
                                <div className="btn btn-sm bg-dark" style={{ marginBottom: 10, color: "#fff", float: "left" }} onClick={clearCache}>Update to latest version</div>
                                <span style={{ float: "right" }} >{ app_version }</span>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};