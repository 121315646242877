import { Types } from './actionTypes';

const initialState = {
  profile: {
    userName: '',
    name: '',
    emailId: '',
    salesPersonId: 0
  },
  isUserLoggedIn: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
            ...state,
            profile: action.payload.user,
            isUserLoggedIn: true
      }
    case Types.LOGOUT:
      localStorage.clear();
      return {
            ...state,
            profile: initialState,
            isUserLoggedIn: false
        }
    default:
      return state;
  }
}

export default reducer;