import { Footer } from '../Widgets';
import React, { useState, useEffect } from 'react';

export const About = () => {
    return (
        <div className='page page-about-us'>
            <section className="module-small" id="profile">
                <div className="container">
                    <div className="row title">
                        <div className="col-sm-6 col-sm-offset-3">
                            <h1 className="module-title font-alt mb-5">Company Profile</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-lg-offset-1">
                            <div className="module-subtitle font-serif align-left" style={{ marginBottom: "10px" }}>
                                <p>
                                    AMRUTH Organic Fertilizers has been manufacturing an array of Bio products like Bio-Fertilizers, Bio pesticides, Organic Manure, Phosphorous Rich Organic Manure, Potash derived from molasses (Bio Potash), Soil Conditioner, Neem Based Organic Manure, Growth Promoters, Micro Nutrients, and Coco Pith.
                                </p>
                                <p>
                                    We offer you a broad range of products – that are ranging from Bio Fertilizers (nutrient management) to Bio pesticides (pest management), crop special consortia, micronutrient liquids, Need Powder, Need Coated Organic Granules, Organic Manure, Plant growth promoters, secondary nutrients, water soluble fertilizers to wetting spreading agent and Need Oil Based products.
                                </p>
                                <p>
                                    For the production line, we have received numerous awards for performance as a manufacturing unit, dynamic workplace culture, safety standards, contributions to the community and environment. With dedication to serve farmers and farm houses well and to help them produce organic products, AMRUTH GROUPS never looked behind.
                                </p>
                                <p>
                                    Now, AMRUTH GROUP has more names – making it the most preferred name in organic world:
                                    <ul>
                                        <li>AMRUTH ORGANIC FERTILIZERS </li>
                                        <li>VARSHINI FERTILIZERS PTV LTD</li>
                                        <li>AGRIONE SHOP – One Stop Solution</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row position-relative m-0">
                        <div className="col-xs-12">
                            <div className="row" style={{ textAlign: "center" }}>
                                <div className="col-sm-4">
                                    <img src="https://s3.ap-south-1.amazonaws.com/amruthgroup.net/images/profile/amruth-group.jpg" alt="amruth-group-logo" style={{ height: "100px", marginBottom: "40px" }} className="content lazyload" />
                                </div>
                                <div className="col-sm-4">
                                    <img src="https://s3.ap-south-1.amazonaws.com/amruthgroup.net/images/profile/varshini-fertilizers.jpg" alt="amruth-varshini-logo" style={{ height: "100px", marginBottom: "40px" }} className="content lazyload" />
                                </div>
                                <div className="col-sm-4">
                                    <img src="https://s3.ap-south-1.amazonaws.com/amruthgroup.net/images/profile/amruth-organic-fertilizers.jpg" alt="amruth-fertilizers-logo" style={{ height: "100px", marginBottom: "25px" }} className="content lazyload" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="content">
                                        <div className="module-subtitle font-serif" style={{ margin: "50px", fontWeight: 400, textAlign: 'left' }}>
                                            <p>
                                                We are the flagship company, spreading cross more than 30 acres sprawling premise at Malladihalli, Holalkere TQ, Chitradurga Dist in the State of Karnataka. The factory is located about 40 km from Chitradurga and 245 km from Bangalore (Benguluru), the state capital city.
                                            </p>
                                            <p>
                                                We are expanding product portfolio on an ongoing basis to ensure significant business growth. We are the proud partners known as one of the largest, premier agro based biotechnology companies – providing with the most advanced goods and services for the sustainable eco-friendly and profitable farm benefits.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row position-relative m-0">
                        <div className="col-xs-12 col-md-6 side-image-text">
                            <div className="row">
                                <div className="content">
                                    <div className="content mapWrapper" style={{ minWidth: "100%", minHeight: "100%", overflow: "hidden", opacity: 1, padding: "0" }}>
                                        <iframe frameBorder="0" style={{ border: 0, height: "300px", width: "100%" }}
                                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCMIvcIG0T_k4DqWFpwcZaPBwQtt2lqc6o&q=Amruth+Organic+Fertilizers,+Malladihalli&zoom=11" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 side-image-text">
                            <div className="row">
                                <div className="col-sm-12 content-holder why">
                                    <div className="content">
                                        <div className="module-subtitle font-serif" style={{ margin: "20px", fontWeight: 400, textAlign: "left" }}>
                                            <b>Why AMRUTH Organic Fertilizers?</b>
                                            <ul>
                                                <li>Quality Assurance, State of art Equipment’s and Production Machinery.</li>
                                                <li>Skilled & Trained Manpower, Timely production of products and Customer satisfaction</li>
                                                <li>Customized production facility, sophisticated storage for finished Goods, and Supervision through the process</li>
                                                <li>More than Decade year of Experience in Manufacturing</li>
                                            </ul>
                                            <p>
                                                Feel free to contact us for the best range of organic products and organic fertilizers, pesticides, and complete range.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    );
};