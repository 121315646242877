import React, { useEffect, useState } from 'react'
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import ArthaOnlineServerGateway from 'service/OnlineServerGateway';
import { Footer, Loader } from '../Widgets';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

export const Product = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [queryParams, setQueryParams] = useSearchParams();
    const [product, setProduct] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (product === null || product === undefined) {
            loadProduct();
        }
    }, []);

    const loadProduct = () => {
        let id = queryParams.get("id");
        ArthaOnlineServerGateway
            .getProduct(id)
            .then((data) => {
                setProduct(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const getImageUrl = (url) => {
        if (url) {
            if (url.startsWith("https://")) {
                return url;
            }
        }
        return "https://s3.ap-south-1.amazonaws.com/amruthgroup.net" + url;
    }

    return (
        <div className="page page-product-detail">
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <>
                    <div className="product-name">{product?.name}</div>
                    <table className="table table-bordered w-100">
                        <tr>
                            <th></th>
                            <th style={{ width: "100px" }}>MRP.</th>
                        </tr>
                        {product?.products.map((p) => (
                            <tr>
                                <td>{p?.variantValue1}  {p?.variantValue2} {p?.variantValue3}</td>
                                <td style={{ textAlign: "right" }}>{currencyFormat(p?.mrp)}/-</td>
                            </tr>
                        ))}
                    </table>
                    <div className="product-description" dangerouslySetInnerHTML={{ __html: product?.description }}></div>
                    {product?.customValues && product?.customValues.length > 0 && product.customValues.map((cv) => (
                        <>
                            {cv?.value && cv?.value.length > 15 &&
                                <>
                                    <h3>{cv?.master.name}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: cv.value }}></div>
                                </>
                            }
                        </>
                    ))}
                    {!user.isUserLoggedIn &&
                        <NavLink to="/login" className="btn btn-sm btn-dark w-100" style={{ marginBottom: "40px", marginTop: "30px" }}>Buy Now</NavLink>
                    }
                </>
            }
            
            <Footer />
            <NavLink className="btn btn-sm bg-danger btn-back-to-home" to={'/products?id=' + product?.group?.id}>Back</NavLink>
        </div>
    )
}