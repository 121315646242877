import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { BusinessSummary } from './BusinessSummary';
import { Ledger } from './Ledger';
import { Outstanding } from './Outstanding';

export const Business = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState<string>("business_summary");

    useEffect(() => {
        if(user.profile) {
            setDealer(user.profile.profile);
        }
      }, [user]);

    return (
        <div className="page-business">
            <ul className="nav">
                <li className="nav-item" onClick={() => setPage("business_summary")}>
                    <h2 className={(page === "business_summary") ? "nav-link active" : "nav-link"}>Summary</h2>
                </li>
                <li className="nav-item"  onClick={() => setPage("ledger")}>
                    <h2 className={(page === "ledger") ? "nav-link active" : "nav-link"}>Statement</h2>
                </li>
                <li className="nav-item"  onClick={() => setPage("outstandings")}>
                    <h2 className={(page === "outstandings") ? "nav-link active" : "nav-link"}>Outstandings</h2>
                </li>
            </ul>
            {(page === "business_summary") &&
                <BusinessSummary />
            }
            {(page === "ledger") &&
                <Ledger />
            }
            {(page === "outstandings") &&
                <Outstanding />
            }
        </div>
    );
}