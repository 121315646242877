import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { faCircleExclamation, faHome } from '@fortawesome/free-solid-svg-icons';

export const PaymentError = () => {
    const [queryParams, setQueryParams] = useSearchParams();

    return (
        <>
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
            <div style={{ textAlign: "center" }}>
                <div className='w-100'>
                    <FontAwesomeIcon icon={faCircleExclamation} size={"4x"} style={{ width: "auto", marginBottom: "40px",color: "red" }} />
                </div>
                <h2>Sorry, something went wrong.<br /> Your payment is not successfull.</h2>
                <h5>{ queryParams.get("message") }</h5>
                <div className="btn-group d-flex w-100" role="group" style={{ marginTop: "40px" }}>
                    <NavLink to="/" className="btn btn-dark w-100">
                        <FontAwesomeIcon icon={faHome} style={{ width: "auto" }} />
                        &nbsp; Back to Home
                    </NavLink>
                </div>
            </div>
            </div>
        </>
    );
};