import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import { Dealer, PendingInvoices } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { RootState } from 'store/store';

export const Outstanding = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<Dealer>();
    const [durationId, setDurationId] = useState<string>("2024");
    const [pendingInvoices, setPendingInvoices] = useState<PendingInvoices[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setDealer(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (!dealer) {
            return;
        }
        setIsLoading(true);
        ArthaGateway
            .getPendingInvoices(dealer.partyId)
            .then((data) => {
                setPendingInvoices(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: dealer.partyId,
                activity: "View outstanding analysis",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }, [dealer, durationId]);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const downloadOutstandingReport = () => {
        ArthaGateway
            .getOutstandingReportFileLink(dealer?.id)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: dealer?.partyId,
                activity: "Download outstanding analysis",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <div>
                    <div className='page-header bg-dark'>
                        <h2>Outstanding Invoices</h2>
                        {pendingInvoices && pendingInvoices.length > 0 &&
                            <div className="btn-group d-flex w-100" role="group" style={{ marginTop: "20px" }}>
                                <div className="btn btn-dark w-100" title="View" onClick={() => downloadOutstandingReport()}>
                                    <FontAwesomeIcon icon={faDownload} style={{ width: "auto" }} />
                                    &nbsp; Download Statement
                                </div>
                            </div>
                        }
                    </div>
                    <ul className="page-items list-group">
                        {pendingInvoices && pendingInvoices.length === 0 &&
                            <span className="badge">No pending invoices</span>
                        }
                        {pendingInvoices && pendingInvoices.map((pendingInvoice) => (
                            (pendingInvoice?.pendingAmount !== undefined && pendingInvoice?.pendingAmount > 0) &&
                            <li className="list-group-item card-item">
                                {(pendingInvoice.pendingAmount > 0) &&
                                    <div style={{ float: "right" }}>
                                        <div className="badge bg-dark" style={{ display: "block", width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                            <div style={{ fontSize: "1.5rem" }}>{pendingInvoice.dueDays}</div> days
                                        </div>
                                        <div className="badge bg-danger" style={{ display: "block", width: "100%", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                            {currencyFormat(pendingInvoice.pendingAmount)}
                                        </div>
                                    </div>
                                }
                                <div>
                                    {pendingInvoice.voucherNumber}
                                </div>
                                <div className='date'>{moment(pendingInvoice.voucherDate).format("DD MMM YYYY")}</div>
                                <div>
                                    <b>{currencyFormat(pendingInvoice.billAmount)}</b>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
    );
};