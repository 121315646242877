import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { faCircleCheck, faHome } from '@fortawesome/free-solid-svg-icons';

export const PaymentSuccess = () => {

    return (
        <>
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
            <div style={{ textAlign: "center" }}>
                <div className='w-100'>
                    <FontAwesomeIcon icon={faCircleCheck} size={"4x"} style={{ width: "auto", marginBottom: "40px",color: "green" }} />
                </div>
                <h2>Thank you.<br /> Your payment received successfully. The amount will be reflected in your statement shortly.</h2>
                <div className="btn-group d-flex w-100" role="group" style={{ marginTop: "40px" }}>
                    <NavLink to="/" className="btn btn-dark w-100">
                        <FontAwesomeIcon icon={faHome} style={{ width: "auto" }} />
                        &nbsp; Back to Home
                    </NavLink>
                </div>
            </div>
            </div>
        </>
    );
};