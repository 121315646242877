import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ArthaGateway from 'service/ArthaGateway';
import { CreditNote, Sale, SaleOrder, Receipt, VoucherSummary } from 'service';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { faFileInvoice, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { durations } from 'service/Utils';

export const Invoices = () => {
    const appdata = useSelector((state: RootState) => state.appdata);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [durationId, setDurationId] = useState<string>("2023");
    const [sales, setSales] = useState<Sale[]>([]);
    const [orders, setOrders] = useState<SaleOrder[]>([]);
    const [creditNotes, setCreditNotes] = useState<CreditNote[]>([]);
    const [receipts, setReceipts] = useState<Receipt[]>([]);
    const [voucherSummary, setVoucherSummary] = useState<VoucherSummary>();
    const [voucherType, setVoucherType] = useState<string>("sale");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(5);
    const [orderSummary, setOrderSummary] = useState<any[]>([]);

    useEffect(() => {
        if (voucherType === "sale") {
            loadSales();
        }
        else if (voucherType === "order") {
            loadOrders();
        }
        else if (voucherType === "receipt") {
            loadReceipts();
        }
        else {
            loadCreditNotes();
        }
    }, [pageNumber]);

    useEffect(() => {
        setSales([]);
        setOrders([]);
        setReceipts([]);
        setCreditNotes([]);
        setPageNumber(1);

        if (voucherType === "sale") {
            loadSalesSummary();
            loadSales();
        }
        else if (voucherType === "order") {
            loadOrderSummary();
            loadOrders();
        }
        else if (voucherType === "receipt") {
            loadReceiptsSummary();
            loadReceipts();
        }
        else {
            loadCreditNotesSummary();
            loadCreditNotes();
        }
    }, [durationId, voucherType]);

    const loadSales = () => {
        setIsLoading(true);
        ArthaGateway
            .getSales(appdata.profile.profile.partyId, durationId, 0, pageNumber, pageSize)
            .then((data) => {
                data.entries.map((sale) => sale.isExpanded = false);
                if (pageNumber > 1) {
                    setSales(sales.concat(data.entries));
                }
                else {
                    setSales(data.entries);
                }
                setTotalPages(data.totalPages);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const loadSalesSummary = () => {
        setIsLoading(true);
        ArthaGateway
            .getSalesSummary(appdata.profile.profile.partyId, durationId, 0)
            .then((data) => {
                setVoucherSummary(data);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: appdata.profile.profile.partyId,
                activity: "View sales summary",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }

    const loadOrders = () => {
        setIsLoading(true);
        ArthaGateway
            .getOrders(appdata.profile.profile.partyId, durationId, 0, 0, pageNumber, pageSize)
            .then((data) => {
                data.entries.map((order) => order.isExpanded = false);
                if (pageNumber > 1) {
                    setOrders(orders.concat(data.entries));
                }
                else {
                    setOrders(data.entries);
                }

                setTotalPages(data.totalPages);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const loadOrderSummary = () => {
        setIsLoading(true);
        ArthaGateway
            .getOrdersSummary(appdata.profile.profile.partyId, durationId, 0, 0)
            .then((data) => {
                let d = {
                    "numberOfInvoices": data.numberOfIvoices,
                    "billAmount": data.billAmount,
                }
                let orderSummary = [
                    {
                        "statusCode": 1,
                        "status": "Pending",
                        "count": 0,
                    },
                    {
                        "statusCode": 2,
                        "status": "Partial",
                        "count": 0,
                    },
                    {
                        "statusCode": 3,
                        "status": "Fullfilled",
                        "count": 0,
                    },
                    {
                        "statusCode": 4,
                        "status": "Cancelled",
                        "count": 0,
                    }
                ];
                data.statusSummary.map((orderStatus) => {
                    orderSummary.map((summary) => {
                        if (summary.statusCode === orderStatus.status) {
                            summary.count = orderStatus.count;
                        }
                    });
                });
                setOrderSummary(orderSummary);
                setVoucherSummary(d);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: appdata.profile.profile.partyId,
                activity: "View order summary",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }

    const loadReceipts = () => {
        setIsLoading(true);
        ArthaGateway
            .getReceipts(appdata.profile.profile.partyId, durationId, 0, pageNumber, pageSize)
            .then((data) => {
                data.entries.map((receipt) => receipt.isExpanded = false);
                if (pageNumber > 1) {
                    setReceipts(receipts.concat(data.entries));
                }
                else {
                    setReceipts(data.entries);
                }

                setTotalPages(data.totalPages);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const loadReceiptsSummary = () => {
        setIsLoading(true);
        ArthaGateway
            .getReceiptsSummary(appdata.profile.profile.partyId, durationId, 0)
            .then((data) => {
                setVoucherSummary(data);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: appdata.profile.profile.partyId,
                activity: "View receipts summary",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }

    const loadCreditNotes = () => {
        setIsLoading(true);
        ArthaGateway
            .getCreditNotes(appdata.profile.profile.partyId, durationId, 0, pageNumber, pageSize)
            .then((data) => {
                data.entries.map((receipt) => receipt.isExpanded = false);
                setCreditNotes(creditNotes.concat(data.entries));
                setTotalPages(data.totalPages);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const loadCreditNotesSummary = () => {
        setIsLoading(true);
        ArthaGateway
            .getCreditNotesSummary(appdata.profile.profile.partyId, durationId, 0)
            .then((data) => {
                setVoucherSummary(data);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: appdata.profile.profile.partyId,
                activity: "View credit notes summary",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }

    const toggleExapnd = (target) => {
        if (voucherType === "sale") {
            sales.map((sale) => {
                if (sale.id === target.id) {
                    sale.isExpanded = !sale.isExpanded;
                }
                else {
                    sale.isExpanded = false;
                }
            });
            setSales([...sales]);
        }
        else if (voucherType === "order") {
            orders.map((order) => {
                if (order.id === target.id) {
                    order.isExpanded = !order.isExpanded;
                }
                else {
                    order.isExpanded = false;
                }
            });
            setOrders([...orders]);
        }
        else if (voucherType === "receipt") {
            receipts.map((receipt) => {
                if (receipt.id === target.id) {
                    receipt.isExpanded = !receipt.isExpanded;
                }
                else {
                    receipt.isExpanded = false;
                }
            });
            setReceipts([...receipts]);
        }
        else {
            creditNotes.map((creditNote) => {
                if (creditNote.id === target.id) {
                    creditNote.isExpanded = !creditNote.isExpanded;
                }
                else {
                    creditNote.isExpanded = false;
                }
            });
            setCreditNotes([...creditNotes]);
        }
    }

    const sendWhatsAppMessage = (sale: Sale) => {
        ArthaGateway
            .getSalesFileLink(sale.id)
            .then((url) => {
                const message = "Dear " + sale.party.name + ",\r\nYour invoice *" + sale.voucherNumber + "* with amount *" + currencyFormat(sale.billAmount) + "/-* is ready. \r\nView details here " + url + ".\r\n\r\nThanks & Regards,\r\nAmruth Organic Fertilizers, Malladihalli";
                let whatsappUrl = 'https://wa.me/+91'
                    + sale.party.mobileNumber
                    + '?text='
                    + encodeURIComponent(message);
                window.open(whatsappUrl, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const viewInvoiceData = (sale: Sale) => {
        ArthaGateway
            .getSalesFileLink(sale.id)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const viewOrderData = (salesOrder: SaleOrder) => {
        ArthaGateway
            .getSalesOrderFileLink(salesOrder.id)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const viewReceiptData = (receipt: Receipt) => {
        ArthaGateway
            .getReceiptFileLink(receipt.id)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const viewCreditNoteData = (creditNote: CreditNote) => {
        ArthaGateway
            .getCreditNoteFileLink(creditNote.id)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }


    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <div className='page-vouchers'>
            <ul className="nav">
                <li className="nav-item" onClick={() => setVoucherType("sale")}>
                    <h2 className={(voucherType === "sale") ? "nav-link active" : "nav-link"}>Invoices</h2>
                </li>
                <li className="nav-item" onClick={() => setVoucherType("order")}>
                    <h2 className={(voucherType === "order") ? "nav-link active" : "nav-link"}>Orders</h2>
                </li>
                <li className="nav-item" onClick={() => setVoucherType("receipt")}>
                    <h2 className={(voucherType === "receipt") ? "nav-link active" : "nav-link"}>Receipts</h2>
                </li>
                {appdata && appdata.profile.profile && appdata?.profile?.profile?.enableCreditNote &&
                    <li className="nav-item" onClick={() => setVoucherType("credit_note")}>
                        <h2 className={(voucherType === "credit_note") ? "nav-link active" : "nav-link"}>Credit Notes</h2>
                    </li>
                }
            </ul>
            <div className='page-header bg-dark'>
                {(voucherType === "sale") &&
                    <h2>Invoices</h2>
                }
                {(voucherType === "order") &&
                    <h2>Orders</h2>
                }
                {(voucherType === "receipt") &&
                    <h2>Receipts</h2>
                }
                {(voucherType === "credit_note") &&
                    <h2>Credit Notes</h2>
                }
            </div>
            <div style={{ margin: "-90px 1rem 10px" }}>
                <select className="form-control" id="duration" value={durationId} onChange={(e) => setDurationId(e.target.value)}>
                    {durations.map((duration) => (
                        <option key={duration.value} value={duration.value}>{duration.label}</option>
                    ))}
                    <option value="-1">Other</option>
                </select>
            </div>
            <div className='page-invoice-list'>
                {(voucherType === "order") &&
                    <div className="row" style={{ margin: "0 0.5rem 20px" }}>
                        {orderSummary && orderSummary.map((summary) => (
                            <div className="col-3" style={{ paddingRight: "0.25rem" }}>
                                <div className="card bg-light" style={{ color: "#000" }}>
                                    {summary.status}
                                    <div className="inner">
                                        <h4>{summary.count}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {(voucherType !== "order") &&
                    <div className="row" style={{ margin: "0 0.5rem 20px" }}>
                        <div className="col-4" style={{ paddingRight: "0.25rem" }}>
                            <div className="card bg-light" style={{ color: "#000" }}>
                                <div className="inner">
                                    <h4>{voucherSummary?.numberOfInvoices}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-8" style={{ paddingLeft: "0.25rem" }}>
                            <div className="card bg-light" style={{ color: "#000" }}>
                                <div className="inner">
                                    <h4>{currencyFormat(voucherSummary?.billAmount)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="accordion" id="dealerAccordion" style={{ marginBottom: 20 }}>
                    {sales && sales.map((sale) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={sale.id.toString()} onClick={() => toggleExapnd(sale)}>
                                <div className={sale.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                    <div style={{ position: "relative" }}>
                                        <b>{sale.voucherNumber}</b>
                                        {moment(sale.date).isSame(moment(), 'day') &&
                                            <div className='badge bg-success badge-small badge-notice'>Today</div>
                                        }
                                        {moment(sale.date).isSame(moment().add(-1, 'days'), 'day') &&
                                            <div className='badge bg-primary badge-small badge-notice'>Yesterday</div>
                                        }
                                        <div style={{ fontWeight: 'normal', fontSize: '0.8rem' }}>{currencyFormat(sale.billAmount)}/-</div>
                                        <div className="date-info">{moment(sale.date).format("DD MMM YYYY")}</div>
                                    </div>
                                </div>
                            </h2>
                            <div id={"d" + sale.id.toString()} className={sale.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d" + sale.id.toString()}>
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Bill Amount
                                            <div className="fw-bold">{currencyFormat(sale.billAmount)}/-</div>
                                        </li>
                                        <li className="list-group-item">
                                            Vehicle Number
                                            <div className="fw-bold">{sale.deliveryData.vehicleNumber}</div>
                                        </li>
                                    </ul>
                                    <div className="btn-group d-flex" role="group" style={{ marginTop: 10 }}>
                                        <div className="btn btn-dark w-100" title="View" onClick={() => viewInvoiceData(sale)}>
                                            <FontAwesomeIcon icon={faFileInvoice} style={{ width: "auto" }} />
                                            &nbsp; Download Invoice
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {orders && orders.map((order) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={order.id.toString()} onClick={() => toggleExapnd(order)}>
                                <div className={order.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                    <div style={{ position: "relative" }}>
                                        <b>{order.voucherNumber}</b>
                                        {moment(order.date).isSame(moment(), 'day') &&
                                            <div className='badge bg-success badge-small badge-notice'>Today</div>
                                        }
                                        {moment(order.date).isSame(moment().add(-1, 'days'), 'day') &&
                                            <div className='badge bg-primary badge-small badge-notice'>Yesterday</div>
                                        }
                                        {order.status === 1 &&
                                            <div className='badge bg-danger badge-notice'>Pending</div>
                                        }
                                        {order.status === 2 &&
                                            <div className='badge bg-info badge-notice'>Patial</div>
                                        }
                                        {order.status === 3 &&
                                            <div className='badge bg-success badge-notice'>Fullfilled</div>
                                        }
                                        {order.status === 4 &&
                                            <div className='badge bg-danger badge-notice'>Cancelled</div>
                                        }
                                        <div style={{ fontWeight: 'normal', fontSize: '0.8rem' }}>{currencyFormat(order.billAmount)}/-</div>
                                        <div className="date-info">{moment(order.date).format("DD MMM YYYY")}</div>
                                    </div>

                                </div>
                            </h2>
                            <div id={"o" + order.id.toString()} className={order.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"o" + order.id.toString()}>
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Bill Amount
                                            <div className="fw-bold">{currencyFormat(order.billAmount)}/-</div>
                                        </li>
                                    </ul>
                                    <div className="btn-group d-flex" role="group" style={{ marginTop: 10 }}>
                                        <div className="btn btn-dark w-100" title="View" onClick={() => viewOrderData(order)}>
                                            <FontAwesomeIcon icon={faFileInvoice} style={{ width: "auto" }} />
                                            &nbsp; Download Order
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {creditNotes && creditNotes.map((creditNote) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={creditNote.id.toString()} onClick={() => toggleExapnd(creditNote)}>
                                <div className={creditNote.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                    <div className="accordion-voucher-header">
                                        <b>{creditNote.voucherNumber}</b>
                                        {moment(creditNote.date).isSame(moment(), 'day') &&
                                            <div className='badge bg-success badge-small badge-notice'>Today</div>
                                        }
                                        {moment(creditNote.date).isSame(moment().add(-1, 'days'), 'day') &&
                                            <div className='badge bg-primary badge-small badge-notice'>Yesterday</div>
                                        }
                                        <div className='badge bg-success'>{currencyFormat(creditNote.amount)}/-</div>
                                        <div className="date-info">{moment(creditNote.date).format("DD MMM YYYY")}</div>
                                    </div>
                                </div>
                            </h2>
                            <div id={"c" + creditNote.id.toString()} className={creditNote.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"c" + creditNote.id.toString()}>
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Amount
                                            <div className="fw-bold">{currencyFormat(creditNote.amount)}/-</div>
                                        </li>
                                        <li className="list-group-item">
                                            Notes
                                            <div className="fw-bold">{creditNote.notes}</div>
                                        </li>
                                    </ul>
                                    <div className="btn-group d-flex" role="group" style={{ marginTop: 10 }}>
                                        <div className="btn btn-dark w-100" title="View" onClick={() => viewCreditNoteData(creditNote)}>
                                            <FontAwesomeIcon icon={faFileInvoice} style={{ width: "auto" }} />
                                            &nbsp; Download Credit Note
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {receipts && receipts.map((receipt) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={receipt.id.toString()} onClick={() => toggleExapnd(receipt)}>
                                <div className={receipt.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                    <div className="accordion-voucher-header">
                                        <b>{receipt.voucherNumber}</b>
                                        {moment(receipt.date).isSame(moment(), 'day') &&
                                            <div className='badge bg-success badge-small badge-notice'>Today</div>
                                        }
                                        {moment(receipt.date).isSame(moment().add(-1, 'days'), 'day') &&
                                            <div className='badge bg-primary badge-small badge-notice'>Yesterday</div>
                                        }
                                        <div className='badge bg-success'>{currencyFormat(receipt.amount)}/-</div>
                                        <div className="date-info">{moment(receipt.date).format("DD MMM YYYY")}</div>
                                    </div>
                                </div>
                            </h2>
                            <div id={"r" + receipt.id.toString()} className={receipt.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"r" + receipt.id.toString()}>
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Date
                                            <div className="fw-bold">{moment(receipt.date).format("DD MMM YYYY")}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Amount
                                            <div className="fw-bold">{currencyFormat(receipt.amount)}/-</div>
                                        </li>
                                        <li className="list-group-item">
                                            Ref No.
                                            <div className="fw-bold">{receipt.referenceNumber}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Notes
                                            <div className="fw-bold">{receipt.notes}</div>
                                        </li>
                                    </ul>
                                    <div className="btn-group d-flex" role="group" style={{ marginTop: 10 }}>
                                        <div className="btn btn-dark w-100" title="View" onClick={() => viewReceiptData(receipt)}>
                                            <FontAwesomeIcon icon={faFileInvoice} style={{ width: "auto" }} />
                                            &nbsp; Download Receipt
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {(totalPages > pageNumber) &&
                    <>
                        {isLoading &&
                            <div className="btn btn-dark w-100 text-center">
                                <div className="spinner-border text-light" role="status">
                                </div>
                            </div>
                        }
                        {!isLoading &&
                            <div className="btn btn-dark w-100 text-center" onClick={() => setPageNumber(pageNumber + 1)}>Load More...</div>
                        }
                    </>
                }
            </div>
        </div>
    );
};