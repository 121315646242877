import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

export const Profile = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if(user.profile) {
            setDealer(user.profile.profile);
        }
      }, [user]);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <div className="page-profile">
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    Name
                    <div className="fw-bold">{dealer?.name}</div>
                </li>
                <li className="list-group-item">
                    Dealer Type
                    <div className="fw-bold">{dealer?.dealerTypeName}</div>
                </li>
                <li className="list-group-item">
                    Mobile
                    <div className="fw-bold">{dealer?.mobileNumber}</div>
                </li>
                <li className="list-group-item">
                    Email
                    <div className="fw-bold">{(dealer?.email && dealer?.email?.length > 0) ? dealer.email : "--" }</div>
                </li>
                <li className="list-group-item">
                    Address
                    <div className="fw-bold">
                        {(dealer?.billingAddress1 && dealer?.billingAddress1?.length > 0) ? dealer?.billingAddress1 : "--" }
                        {(dealer?.billingAddress2 && dealer?.billingAddress2?.length > 0) &&
                            <><br /><span>{dealer.billingAddress2}</span></>
                        }
                        {(dealer?.billingCity && dealer?.billingCity?.length > 0) &&
                            <><br /><span>{dealer.billingCity}</span></>
                        }
                        {(dealer?.billingCity && dealer?.billingCity?.length > 0) &&
                            <><br /><span>{dealer.billingCity}</span></>
                        }
                        {(dealer?.billingPinCode && dealer?.billingPinCode?.length > 0) &&
                            <><br /><span>{dealer.billingPinCode}</span></>
                        }
                    </div>
                </li>
                <li className="list-group-item">
                    GSTIN
                    <div className="fw-bold">{dealer.gstin}</div>
                </li>
                <li className="list-group-item">
                    PAN
                    <div className="fw-bold">{dealer.pan}</div>
                </li>
                <li className="list-group-item">
                    Cheque Number
                    <div className="fw-bold">{dealer.chequeNumber}</div>
                </li>
                <li className="list-group-item">
                    License Number
                    <div className="fw-bold">{(dealer?.licenseNumber && dealer?.licenseNumber?.length > 0) ? dealer.licenseNumber : "--" }</div>
                </li>
                <li className="list-group-item">
                    License Number Valid Upto
                    <div className="fw-bold">{(dealer?.licenseNumber && dealer?.licenseNumber?.length > 0) ? moment(dealer.licenseNumberValidUpto).format("DD/MM/YYYY") : "--" }</div>
                </li>
                <li className="list-group-item">
                    Status
                    <div className="fw-bold">{dealer.status}</div>
                </li>
                <li className="list-group-item">
                    Notes
                    <div className="fw-bold">
                    {(dealer?.notes && dealer?.notes?.length > 0) ? <div dangerouslySetInnerHTML={{ __html: dealer.notes }} /> : "--"}
                    </div>
                </li>
                <li className="list-group-item">
                    Balance<br />
                    {dealer.totalBalance > 0 &&
                        <div className="badge bg-danger" style={{ fontSize: "1.2em" }}>{currencyFormat(dealer.totalBalance)}</div>
                    }
                    {dealer.totalBalance <= 0 &&
                        <div className="badge bg-success" style={{ fontSize: "1.2em" }}>No Outstanding</div>
                    }
                </li>
            </ul>
        </div>
    );
}