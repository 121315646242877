import { Footer, InstallPWA } from '../Widgets';
import ArthaOnlineServerGateway from 'service/OnlineServerGateway';
import React, { useState, useEffect } from 'react';
import { ProductGroup } from 'service/Models';
import { Loader } from '../Widgets';
import { NavLink } from 'react-router-dom';
import { app_version, clearCache } from 'service/Utils';

export const Home = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [categories, setCategories] = useState<ProductGroup[]>([]);

    useEffect(() => {
        if (categories === null || categories === undefined || categories.length === 0) {
            loadProductGroups();
        }
    }, []);

    const loadProductGroups = () => {
        ArthaOnlineServerGateway
            .getAllProductGroups()
            .then((data) => {
                setCategories(data);
                sessionStorage.setItem("productGroups", JSON.stringify(data));
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getImagePathForSrc = (category) => {
        if (category.imageUrl && category.imageUrl.length > 0) {
            return category.imageUrl;
        }
        return "https://s3.ap-south-1.amazonaws.com/amruthgroup.net/images/category/" + category.code + ".jpg";
    }

    return (
        <div className="page page-home">
            <div className="bg-image"
                style={{
                    height: "calc(100vh - 64px)",
                    backgroundImage: `url('https://s3.ap-south-1.amazonaws.com/amruthgroup.net/images/home.jpg')`,
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "2rem",
                    fontWeight: "bolder",
                    overflow: "hidden"
                }}>                    
                <div style={{ position: "absolute", top: "150px", width: "50vw", margin: "auto" }}>
                    <InstallPWA />
                </div>
                Welcome to,<br />Amruth Organic Fertilizers<br />Dealers App.

                <div className='version-number'>
                    <div className="btn btn-sm bg-dark" style={{ marginLeft: 20, marginBottom: 10, color: "#fff", float: "left" }} onClick={clearCache}>Update to latest version</div>
                    <span style={{ marginRight: 20, float: "right" }} >{ app_version }</span>
                </div>
            </div>
            <section className="module pb-0" id="works">
                <div className="container">
                    <div className="row" style={{ padding: "20px 0" }}>
                        <div className="col-sm-12 col-offset-2">
                            <h2 className="module-title font-alt">Our Products</h2>
                            <div className="module-subtitle font-serif" style={{ fontWeight: 400 }}>Amruth organic fertilizers is manufacturing an array of Bio products like Bio-Fertilizers, Bio pesticides, Organic Manure , Phosphorous Rich Organic Manure,  Potash derived from molasses(Bio Potash), Soil Conditioner, Neem Based Organic Manure, Growth Promoters, Micro Nutrients, Coco Pith.</div>
                        </div>
                    </div>

                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <div className="container-fluid">
                            <div className="row">
                                {categories && categories.map((category) => (
                                    <div className="col-sm-6 col-md-3 group-card" style={{ minHeight: "260px" }}>
                                        <div className="group-item">
                                            <div className="group-image">
                                                <img src={getImagePathForSrc(category)} alt={category.name} className="lazyload" />
                                            </div>
                                            <div className="group-descr font-alt">
                                                <div className="group-name">{category.name}</div>
                                                <NavLink className="btn btn-sm btn-dark w-100" to={'/products?id=' + category.id}>Details</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </section>
            <Footer />
        </div>
    );
};