import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Dealer, Transaction } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { durations } from 'service/Utils';

export const Ledger = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<Dealer>();
    const [durationId, setDurationId] = useState<string>("2023");
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setDealer(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (!dealer) {
            return;
        }
        setIsLoading(true);
        ArthaGateway
            .getTransactions(dealer.accountId, durationId, pageNumber)
            .then((data) => {
                setTransactions(transactions.concat(data.entries));
                setTotalPages(data.totalPages);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: dealer.partyId,
                activity: "View account statement",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }, [dealer, durationId, pageNumber]);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const reloadData = (e) => {
        setTransactions([]);
        setDurationId(e.target.value)
    };

    const downloadStatement = () => {
        ArthaGateway
            .getDealerStatementFileLink(dealer?.accountId, durationId)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: dealer?.partyId,
                activity: "Download account statement",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <div>
                    <div className='page-header bg-dark'>
                        <h2>Account Statement</h2>
                        {transactions && transactions.length > 0 &&
                            <div className="btn-group d-flex w-100" role="group" style={{ marginTop: 10 }}>
                                <div className="btn btn-dark w-100" title="Share" onClick={() => downloadStatement()}>
                                    <FontAwesomeIcon icon={faDownload} style={{ width: "auto" }} />
                                    &nbsp; Download Statement
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ margin: "-60px 1rem 30px" }}>
                        <select className="form-control" id="duration" value={durationId} onChange={reloadData}>
                            {durations.map((duration) => (
                                <option key={duration.value} value={duration.value}>{duration.label}</option>
                            ))}
                            <option value="-1">Other</option>
                        </select>
                    </div>
                    {(transactions.length === 0) &&
                        <div className='container'>
                            <div className="alert alert-secondary">No transactions during this period</div>
                        </div>
                    }
                    <ul className="list-group">
                        {transactions && transactions.map((transaction) => (
                            (transaction.credit > 0 || transaction.debit > 0) &&
                            <li className="list-group-item card-item">
                                <div className="fw-bold">
                                    {transaction.voucherType} - {transaction.voucherNumber}
                                </div>
                                <div className='date'>{moment(transaction.transactionDate).format("DD MMM YYYY")}</div>
                                <div className="desc">{transaction.description}</div>
                                <div className='cb'>CB: {currencyFormat(transaction.closingBalance)}</div>
                                {transaction.debit > 0 &&
                                    <div className='badge bg-danger badge-lg' style={{ float: 'right' }}>{currencyFormat(transaction.debit)}</div>
                                }
                                {transaction.credit > 0 &&
                                    <div className='badge bg-success badge-lg' style={{ float: 'right' }}>{currencyFormat(transaction.credit)}</div>
                                }
                            </li>
                        ))}
                        {(totalPages > pageNumber) &&
                            <div className="btn btn-primary" onClick={() => setPageNumber(pageNumber + 1)}>Load More...</div>
                        }
                    </ul>
                </div>
            }
        </>
    );
};