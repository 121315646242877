import axios from 'axios';

export const adminServerUrl = `${process.env.REACT_APP_ONLINE_ADMIN_URL || ''}`;

axios.defaults.headers.post['Content-Type'] ='application/json';

const ArthaOnlineServerGateway = {
    getAllProductGroups: async function() {
        try {
            let response = await axios.get(adminServerUrl + '/master/product/group');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaOnlineServerGateway::getAllProductGroups ->"+ error);
            throw error;
        }
        return undefined;
    },
    getAllProducts: async function(groupId) {
        try {
            let response = await axios.get(adminServerUrl + '/master/productMaster', {params: { groupId }});
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaOnlineServerGateway::getAllProducts ->"+ error);
            throw error;
        }
        return undefined;
    },
    getProduct: async function(id) {
        try {
            let response = await axios.get(adminServerUrl + '/master/productMaster/'+ id);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaOnlineServerGateway::getProduct ->"+ error);
            throw error;
        }
        return undefined;
    }
}

export default ArthaOnlineServerGateway;