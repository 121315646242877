import { Loader } from '../Widgets';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import { Dealer, PendingInvoices } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { RootState } from 'store/store';

export const MakePayment = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<Dealer>();
    const [amount, setAmount] = useState<number>(0);
    const [balance, setBalance] = useState<number>(0);
    const [pendingInvoices, setPendingInvoices] = useState<PendingInvoices[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const paymentAmountRef = useRef<null | HTMLInputElement>(null)

    useEffect(() => {
        if (user.profile) {
            setDealer(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (!dealer) {
            return;
        }
        ArthaGateway
            .getBalance(dealer.accountId)
            .then((data) => {
                let totalBalance = data.reduce((sum, d) => sum + d.balance, 0);
                setBalance(totalBalance);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .getPendingInvoices(dealer.partyId)
            .then((data) => {
                data.map((d) => d.isSelected = false);
                setPendingInvoices(data);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, [dealer]);

    const initiatePayment = () => {
        ArthaGateway
            .initiatePayment(dealer?.partyId, amount)
            .then((data) => {
                console.log(data);
                if(data && data.length > 0) {
                    window.location.href = data;
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const selectAllInvoices = () => {
        pendingInvoices.map((invoice) => {
            invoice.isSelected = true;
        });
        setAmount(pendingInvoices.reduce((sum, d) => sum + d.pendingAmount, 0));
        if(paymentAmountRef != null && paymentAmountRef.current != null) {
            paymentAmountRef.current.scrollIntoView();
        }
    }

    const selectInvoice = (invoice: PendingInvoices) => {
        invoice.isSelected = true;
        setAmount(amount + invoice.pendingAmount);
        if(paymentAmountRef != null && paymentAmountRef.current != null) {
            paymentAmountRef.current.scrollIntoView();
        }
    }

    const deselectInvoice = (invoice: PendingInvoices) => {
        invoice.isSelected = false;
        setAmount(amount - invoice.pendingAmount);
        if(paymentAmountRef != null && paymentAmountRef.current != null) {
            paymentAmountRef.current.scrollIntoView();
            paymentAmountRef.current.focus();
        }
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <div>
                    <div className='page-header bg-dark'>
                        <h2>Pay</h2>
                        <div style={{ marginTop: "20px" }}>
                            <input type="number" 
                                className="form-control" 
                                value={amount} 
                                placeholder="Enter amount"
                                onChange={(e) => setAmount(parseInt(e.target.value))}
                                style={{ fontWeight: "bold", fontSize: "2rem", textAlign: "center" }}
                                ref={paymentAmountRef} />
                        </div>
                        {amount > 0 &&
                            <div className="btn-group d-flex w-100" role="group" style={{ marginTop: "20px" }}>
                                <div className="btn btn-dark w-100" title="View" onClick={() => initiatePayment()}>
                                    <FontAwesomeIcon icon={faCreditCard} style={{ width: "auto" }} />
                                    &nbsp; Make Payment
                                </div>
                            </div>
                        }
                    </div>
                    <ul className="page-items list-group">
                        {pendingInvoices && pendingInvoices.length === 0 &&
                            <span className="badge">No pending invoices</span>
                        }
                        {pendingInvoices && pendingInvoices.length > 0 &&
                            <li className="list-group-item card-item">
                                {(dealer && dealer?.totalBalance > 0) &&
                                    <div className="btn btn-sm btn-secondary" style={{ float: "right" }} onClick={() => selectAllInvoices() }>
                                        Select All
                                    </div>
                                }
                                <div>
                                    Total Balance
                                </div>
                                <div>
                                    <b>{currencyFormat(balance)}</b>
                                </div>
                            </li>
                        }
                        {pendingInvoices && pendingInvoices.map((pendingInvoice) => (
                            (pendingInvoice?.pendingAmount !== undefined && pendingInvoice?.pendingAmount > 0) &&
                            <li className="list-group-item card-item">
                                {(pendingInvoice.pendingAmount > 0) && !pendingInvoice.isSelected &&
                                    <div className="btn btn-sm btn-secondary" style={{ float: "right" }} onClick={() => selectInvoice(pendingInvoice) }>
                                        Select
                                    </div>
                                }
                                {(pendingInvoice.pendingAmount > 0) && pendingInvoice.isSelected &&
                                    <div className="btn btn-sm btn-success" style={{ float: "right" }} onClick={() => deselectInvoice(pendingInvoice) }>
                                        <FontAwesomeIcon icon={faCircleCheck} style={{ width: "auto" }} />
                                        &nbsp; Selected
                                    </div>
                                }
                                <div>
                                    {pendingInvoice.voucherNumber}
                                </div>
                                <div className='date'>{moment(pendingInvoice.voucherDate).format("DD MMM YYYY")}</div>
                                <div>
                                    <b>{currencyFormat(pendingInvoice.pendingAmount)}</b>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
    );
};