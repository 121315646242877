import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dealer } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { RootState } from 'store/store';

export const HandlePayment = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [queryParams, setQueryParams] = useSearchParams();
    const [dealer, setDealer] = useState<Dealer>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.profile) {
            setDealer(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (!dealer) {
            return;
        }
        let tid = queryParams.get("tid");
        let churn = queryParams.get("churn");
        console.log(tid, churn);
        ArthaGateway
            .processPayment(tid, churn)
            .then((data) => {
                setIsLoading(false);
                data = JSON.parse(data);
                console.log(data);
                if(data.Status === "PAYMENT_SUCCESS") {
                    navigate("/payment/success?message=" + encodeURIComponent(data.Message), { replace: true });
                }
                else {
                    navigate("/payment/error?message=" + encodeURIComponent(data.Message), { replace: true });
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, [dealer]);

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <div>
                    Payment received
                </div>
            }
        </>
    );
};