import { Footer, Loader } from '../Widgets';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { ProductGroup } from 'service/Models';
import ArthaOnlineServerGateway from 'service/OnlineServerGateway';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

export const ProductList = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [queryParams, setQueryParams] = useSearchParams();
    const [products, setProducts] = useState<any[]>([]);
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const [productGroup, setProductGroup] = useState<ProductGroup>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        productGroups.map((productGroup) => {
            if (productGroup.id.toString() === queryParams.get("id")) {
                setProductGroup(productGroup);
            }
            return productGroup;
        });
    }, [productGroups]);

    useEffect(() => {
        if (products === null || products === undefined || products.length === 0) {
            loadProductGroups();
            loadProducts();
        }
    }, []);

    const loadProductGroups = () => {
        let data = sessionStorage.getItem("productGroups");
        if (data && data.length > 0) {
            setProductGroups(JSON.parse(data));
        } else {
            ArthaOnlineServerGateway
                .getAllProductGroups()
                .then((data) => {
                    setProductGroups(data);

                    sessionStorage.setItem("productGroups", JSON.stringify(data));
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const loadProducts = () => {
        let id = queryParams.get("id");
        ArthaOnlineServerGateway
            .getAllProducts(id)
            .then((data) => {
                setProducts(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getImageUrl = (url) => {
        if (url) {
            if (url.startsWith("https://")) {
                return url;
            }
        }
        return "https://s3.ap-south-1.amazonaws.com/amruthgroup.net" + url;
    }

    return (
        <div className="page page-product-list">
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <>
                    <div className="group-name">{productGroup?.name}</div>
                    <div className='row'>
                    {products.map((product) => {
                        return (
                            <>
                                {(product.enableOnlineSales || product.enablePOSSales) &&
                                    <div className="col-sm-6 col-md-3 col-lg-3" key={product.id}>
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={getImageUrl(product.imageUrl)} className="lazyload" alt={product.code} />
                                            </div>
                                            <h5 className="card-title">{product.name}</h5>
                                            <div className="card-text" dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                            <NavLink className="btn btn-sm btn-dark w-100" to={"/product?id=" + product.id}>Details</NavLink>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    })}
                    </div>
                </>
            } 
            <Footer />
            <NavLink className="btn btn-sm bg-danger btn-back-to-home" to="/productCategory">Back</NavLink>
        </div>

    )
}