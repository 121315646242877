import { Footer, Loader } from '../Widgets';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { ProductGroup } from 'service/Models';
import ArthaOnlineServerGateway from 'service/OnlineServerGateway';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

export const ProductCategory = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [categories, setCategories] = useState<ProductGroup[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (categories === null || categories === undefined || categories.length === 0) {
            loadProductGroups();
        }
    }, []);

    const loadProductGroups = () => {
        let data = sessionStorage.getItem("productGroups");
        if (data && data.length > 0) {
            setCategories(JSON.parse(data));
            setIsLoading(false);
        } else {
            ArthaOnlineServerGateway
                .getAllProductGroups()
                .then((data) => {
                    setCategories(data);

                    sessionStorage.setItem("productGroups", JSON.stringify(data));
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const getImagePathForSrc = (category) => {
        if (category.imageUrl && category.imageUrl.length > 0) {
            return category.imageUrl;
        }
        return "https://s3.ap-south-1.amazonaws.com/amruthgroup.net/images/category/" + category.code + ".jpg";
    }

    return (
        <div className="page page-product-category">
            <section className="module pb-0" id="works">
                <div className="container">
                    <div className="row" style={{ padding: "20px 0" }}>
                        <div className="col-sm-12 col-offset-2">
                            <h2 className="module-title font-alt">Our Products</h2>
                            <div className="module-subtitle font-serif" style={{ fontWeight: 400 }}>Amruth organic fertilizers is manufacturing an array of Bio products like Bio-Fertilizers, Bio pesticides, Organic Manure , Phosphorous Rich Organic Manure,  Potash derived from molasses(Bio Potash), Soil Conditioner, Neem Based Organic Manure, Growth Promoters, Micro Nutrients, Coco Pith.</div>
                        </div>
                    </div>

                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <div className="container-fluid">
                            <div className="row">
                                {categories && categories.map((category) => (
                                    <div className="col-sm-6 col-md-3 group-card" style={{ minHeight: "260px" }} key={category.id}>
                                        <div className="group-item">
                                            <div className="group-image">
                                                <img src={getImagePathForSrc(category)} alt={category.name} className="lazyload" />
                                            </div>
                                            <div className="group-descr font-alt">
                                                <div className="group-name">{category.name}</div>
                                                <NavLink className="btn btn-sm btn-dark w-100" to={'/products?id=' + category.id}>Details</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </section>            
            <Footer />
            <NavLink className="btn btn-sm bg-danger btn-back-to-home" to="/">Back</NavLink>
        </div>

    )
}