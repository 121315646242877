import { Footer } from '../Widgets';
import React, { useState, useEffect } from 'react';

export const Privacy = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-10 col-lg-offset-1">
                        <h2 className="module-title font-alt"><b>Privacy Policy</b></h2>
                        <div className="module-subtitle font-serif align-left" style={{ marginBottom: "10px" }}>
                            <p>
                                Before purchasing anything and to get the services of AMRUTH GROUP of Companies, you are advised to go through the privacy policy.
                            </p>
                            <p>
                                The privacy policy sets out, “How AMRUTH Organic Fertilizers uses and protects any information – provided to or made available from your end at AMRUTH Organic Fertilizers – all information taken at the time of using the website.
                            </p>
                            <p><b>Amruth Group of companies or AMRUTH Group is committed to ensure that your privacy is protected.</b></p>
                            <p>
                                We can ask you for certain information by which you can be identified at the time of using this website. You can be assured that it will only be used according to the privacy statement.
                            </p>
                            <p>
                                AMRUTH Organic Fertilizers may change this policy from time to time or we are free to update our information on the pages. We request you to check pages time to time to ensure that you are happy with any changes done. Our policy is effective from 1 November 2020.
                            </p>
                            <p>
                                We keep all rights reserved to collect the following information:
                                <ul>
                                    <li>Name and job title</li>
                                    <li>Contact information including email address</li>
                                    <li>Demographic information such as postcode, preferences and interests</li>
                                    <li>Other information relevant to customer surveys and/or offers</li>
                                </ul>
                            </p>
                            <p>
                                What we do with the information we gather. We require this information to understand your needs and provide you with a better service and in particular for the reasons that are given here:
                                <ul>
                                    <li>Internal record keeping.</li>
                                    <li>We may use the information to improve our products and services.</li>
                                    <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                                    <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
                                </ul>
                            </p>
                            <p>
                                In addition to this, we may also use your information to contact you for market research purposes. It may happen from time to time. We may contact you by email, phone, fax or mail. In addition to this, we may use the information to customize the website according to your interests.
                            </p>
                            <p><b>Know About Our Security Policy</b></p>
                            <p>
                                AMRUTH Group is committed to ensure that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
                            </p>
                            <p><b>How We Use Cookies?</b></p>
                            <p>
                                A cookie is a small file which asks permission to be placed on your computer's hard drive. As soon as you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site.
                                <ul>
                                    <li>Cookies allow web applications to respond to you as an individual.</li>
                                    <li>The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</li>
                                    <li>We use traffic log cookies to identify which pages are being used.</li>
                                </ul>
                            </p>
                            <p>
                                In this way, this way helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
                            </p>
                            <p>
                                Cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not.
                            </p>
                            <p>
                                A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                            </p>
                            <p>
                                <b>Links to Other Websites</b>
                            </p>
                            <p>
                                Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website.
                            </p>
                            <p>
                                We cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement.
                            </p>
                            <p>
                                You should exercise caution and look at the privacy statement applicable to the website in question.
                            </p>
                            <p><b>Controlling Your Personal Information</b></p>
                            <p>
                                You may choose to restrict the collection or use of your personal information in the following ways:
                            </p>
                            <p>
                                Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes
                            </p>
                            <p>
                                If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@amruthgroup.net
                            </p>
                            <p>
                                We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.
                            </p>
                            <p>
                                We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
                            </p>
                            <p>
                                You may request details of personal information which we hold about you under the Data Protection Act 1998.
                            </p>
                            <p><b>A small fee will be payable. If you would like a copy of the information held on you please write to:</b></p>
                            <p>
                                Amruth Organic Fertilizers,
                            </p>
                            <p>
                                Malladihalli, Holalkere(T), Chitradurga - 577531, Karnataka, India
                            </p>
                            <p>
                                If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address.
                            </p>
                            <p>
                                We will promptly correct any information found to be incorrect.
                            </p>
                            <p><b>Contact Us</b></p>
                            <p>
                                If there are any questions regarding this privacy policy you may contact us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};