import { Footer } from '../Widgets';
import React, { useState, useEffect } from 'react';

export const Shipping = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-10 col-lg-offset-1">
                        <h2 className="module-title font-alt"><b>Delivery\Shipping Policy</b></h2>
                        <div className="module-subtitle font-serif align-left" style={{ marginBottom: "10px" }}>
                            <ul>
                                <li>
                                    To ensure that your order reaches you in our standard time (8 to 15 working days) and in good condition, we will ship through standard courier agency
                                    Note: If we will ship through postal service then it may take more time.
                                </li>
                                <li>If you are a new user then, our executive will contact you and confirm your order (For perfect delivery our executive will confirm you your exact delivery address and pin code). If your Pincode is not serviceable or we are not able to send the material then, you have to give us another address and pin code.</li>
                                <li>If you believe that the product is not in good condition, or if the packaging is tampered with or damaged before accepting delivery of the goods, please refuse to take delivery of the package, click some pictures of the package and write instruction to courier boy (The package is tampered or damaged in courier so, I will not accept the order) and send us a mail at info@amruthgroup.net mentioning your order reference number and attached pictures or call our Customer Care. We shall make our best efforts to ensure that a replacement delivery is made to you at the earliest.</li>
                                <li>Delivery time mentioned on the product, cart page or website is estimated. Actual delivery time is based on the availability of a product, weather condition and address where the product is to be delivered and courier company's rules. Note: If your address is ODA location then, you have to self collect the parcel from the courier office. We will provide a courier office address and number. Also, our customer care executive will keep in touch with you. 5) For any issues in utilizing our services you may write to info@amruthgroup.net</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};