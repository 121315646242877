import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { BusinessSummaryModel, Dealer, Transaction } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { durations } from 'service/Utils';
import moment from 'moment';

export const BusinessSummary = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [dealer, setDealer] = useState<Dealer>();
    const [durationId, setDurationId] = useState<string>("2023");
    const [businessSummary, setBusinessSummary] = useState<BusinessSummaryModel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setDealer(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (!dealer) {
            return;
        }
        setIsLoading(true);
        ArthaGateway
            .getBusinessSummary(dealer?.partyId, durationId)
            .then((data) => {
                setBusinessSummary(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

        ArthaGateway
            .logActivity({
                partyId: dealer.partyId,
                activity: "View business summary",
                performedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
    }, [dealer, durationId]);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const reloadData = (e) => {
        setDurationId(e.target.value)
    };

    const getNetSales = () => {
        let netSales = 0;
        if (businessSummary?.sales !== undefined) {
            netSales += businessSummary?.sales;
        }
        if (businessSummary?.return !== undefined) {
            netSales -= businessSummary?.return;
        }
        if (businessSummary?.creditNote !== undefined) {
            netSales -= businessSummary?.creditNote;
        }
        if (businessSummary?.journal !== undefined) {
            netSales -= businessSummary?.journal;
        }
        return netSales;
    }

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <div>
                    <div className='page-header bg-dark'>
                        <h2>Business Summary</h2>
                    </div>
                    <div style={{ margin: "-60px 1rem 30px" }}>
                        <select className="form-control" id="duration" value={durationId} onChange={reloadData}>
                            {durations.map((duration) => (
                                <option key={duration.value} value={duration.value}>{duration.label}</option>
                            ))}
                        </select>
                    </div>
                    <ul className="list-group">
                        {businessSummary?.openingBalance !== undefined && businessSummary?.openingBalance > 0 &&
                            <li className="list-group-item card-item bg-light text-center">
                                <b><h1>{currencyFormat(businessSummary?.openingBalance)}</h1></b>
                                Opening Balance
                            </li>
                        }
                        <li className="list-group-item card-item bg-light text-center">
                            <b><h1>{currencyFormat(businessSummary?.sales)}</h1></b>
                            Sales
                        </li>
                        {businessSummary?.return !== undefined && businessSummary?.return > 0 &&
                            <li className="list-group-item card-item bg-light text-center">
                                <b><h1>{currencyFormat(businessSummary?.return)}</h1></b>
                                Return
                            </li>
                        }
                        {(businessSummary?.creditNote !== undefined && businessSummary?.creditNote > 0) &&
                            <li className="list-group-item card-item bg-light text-center">
                                <b><h1>{currencyFormat(businessSummary?.creditNote)}</h1></b>
                                Credit Note
                            </li>
                        }
                        {(businessSummary?.journal !== undefined && businessSummary?.journal > 0) &&
                            <li className="list-group-item card-item bg-light text-center">
                                <b><h1>{currencyFormat(businessSummary?.journal)}</h1></b>
                                Other Adjustments
                            </li>
                        }
                        <li className="list-group-item card-item bg-secondary text-center" style={{ color: "#fff" }}>
                            <b><h1>{currencyFormat(getNetSales())}</h1></b>
                            Net Sales
                        </li>
                        <li className="list-group-item card-item bg-light text-center">
                            <b><h1>{currencyFormat(businessSummary?.receipt)}</h1></b>
                            Receipt
                        </li>
                        <li className={(businessSummary?.closingBalance && businessSummary?.closingBalance > 0) ? "list-group-item card-item bg-danger text-center" : "list-group-item card-item bg-success text-center"} style={{ color: "#fff" }}>
                            <b><h1>{currencyFormat(businessSummary?.closingBalance)}</h1></b>
                            Closing Balance
                        </li>
                    </ul>
                </div>
            }
        </>
    );
};