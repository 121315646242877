import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { BusinessSummary, PartyList, Dashboard, Invoices, Ledger, Login, Outstanding, Profile, Business, MakePayment, HandlePayment, PaymentSuccess, PaymentError, Home, About, Privacy, Cancellation, Shipping, ProductCategory, Product, ProductList } from './Pages';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.js';
import '../scss/style.scss'
import 'bootstrap'
import logo from "../assets/logo.png"
import { ActionCreators } from '../store/actionCreators';
import { RootState } from 'store/store';
import { BottomBar } from './Widgets';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

function App() {
  const user = useSelector((state: RootState) => state.appdata);
  const dispatch = useDispatch();

  const checkLocalStorage = () => {
    console.log("Checking local storage");
    let user_data = localStorage.getItem("user");
    if(user_data && user_data.length > 0) {
      let login_time = localStorage.getItem("login_time");
      if(login_time && login_time.length > 0) {
        let last_login_time = moment(login_time);
        if(moment().diff(last_login_time, 'days') === 0) {
          let user_info = JSON.parse(user_data);
          dispatch(ActionCreators.login(user_info));
        }
      }
    }
  }

  const isAuthenticated = () => {
    if (!user.isUserLoggedIn) {
      checkLocalStorage();
      if (!user.isUserLoggedIn) {
        return false;
      }
    }
    return true;
  }

  const PrivateRoute = ({children}) => isAuthenticated() ? children : <Home />
  
  return (
    <div id="app">
      <Router>
        <nav className="navbar sticky-top navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} width="30" height="30" alt="" style={{ marginRight: 5 }} />
              Amruth Organic Fertilizers
            </a>
              {!user.isUserLoggedIn &&
                <NavLink to="/login" className="btn btn-dark" style={{ marginLeft: 20, float: "right" }}>Login</NavLink>
              }
          </div>
        </nav>
        <div className="container">
          <div className="row" style={{ marginTop: 10, marginBottom: 60 }}>
              <Routes>
                <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute> } />
                <Route path="/invoices" element={<PrivateRoute><Invoices /></PrivateRoute> } />
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute> } />
                <Route path="/business" element={<PrivateRoute><Business /></PrivateRoute> } />
                <Route path="/businessSummary" element={<PrivateRoute><BusinessSummary /></PrivateRoute> } />
                <Route path="/outstanding" element={<PrivateRoute><Outstanding /></PrivateRoute> } />
                <Route path="/payment" element={<PrivateRoute><MakePayment /></PrivateRoute> } />
                <Route path="/payment/result" element={<PrivateRoute><HandlePayment /></PrivateRoute> } />
                <Route path="/payment/success" element={<PrivateRoute><PaymentSuccess /></PrivateRoute> } />
                <Route path="/payment/error" element={<PrivateRoute><PaymentError /></PrivateRoute> } />
                <Route path="/home" element={ <Home /> } />
                <Route path="/login" element={ <Login /> } />
                <Route path="/about" element={ <About /> } />
                <Route path="/privacy" element={ <Privacy /> } />
                <Route path="/cancellation" element={ <Cancellation /> } />
                <Route path="/shipping" element={ <Shipping /> } />
                <Route path="/productCategory" element={ <ProductCategory /> } />
                <Route path="/products" element={ <ProductList /> } />
                <Route path="/product" element={ <Product /> } />
              </Routes>
          </div>
        </div>
        {user.isUserLoggedIn &&
          <BottomBar />
        }      
      </Router>
    </div>
  );
}

export default App;
